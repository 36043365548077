import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Link } from 'gatsby'
import { colors } from '../../tailwind'
import SiteLogo from '../../static/SiteLogoNew.png'

const NavBarInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: fixed;
  z-index: 1000;
  background: ${colors['orange-light']};
`

const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const MenuList = styled.ul`
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin: 0;
`

const Logo = styled.img`
  ${tw`sm:ml-1 md:ml-20 xl:ml-40`}
  ${tw`p-2`}
  height: 65px;
`

const MenuListItem = styled.li`
  display: inline-block;
  float: left;
  margin-right: 1px;
  &:hover ${LinkWrapper} a {
    color: ${colors['grey-light']};
  }
`
const LinkWrapper = styled.div`
  display:block;
  ${tw`text-white text-xl tracking-wide font-sans p-2 mt-3`}
  color: ${colors.black};
  border-bottom: ${props => (props.active ? `3px solid ${colors.white}` : 'none')};
  text-decoration: none;
  a {
    color: ${props => (props.active ? colors.white : colors.black)};
  }
`

const StaticMenu = [{ title: 'Blog', path: '/blog' }]
const PathMatchRegex = /(\/+\w*)/gi
const Navbar = ({ location: { pathname } }) => (
  <NavBarInner>
    <Link to="/">
      <Logo src={SiteLogo} />
    </Link>
    <MenuWrapper>
      <MenuList>
        {StaticMenu.map(({ title, path }) => (
          <MenuListItem key={`${path}${title.replace(/\s/g, '')}`}>
            <LinkWrapper active={path === pathname.match(PathMatchRegex)[0]}>
              <Link to={path}> {title} </Link>
            </LinkWrapper>
          </MenuListItem>
        ))}
      </MenuList>
    </MenuWrapper>
  </NavBarInner>
)

export default Navbar

Navbar.propTypes = {
  menu: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
