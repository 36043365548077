import React from 'react'
import GlobalStyle from '../styles/global'
import SEO from './SEO'
import Navbar from './Navbar'
import 'typeface-cantata-one'
import 'typeface-open-sans'

const Layout = ({ location , CustomSEO }) => {
  return (

    <>
      { CustomSEO ? <CustomSEO/> : <SEO /> }
      <GlobalStyle />
      <Navbar location={location} />
    </>
  )
}

export default Layout
