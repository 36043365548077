import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Link } from "gatsby"
import { waveAnimation } from '../styles/animations'
const Footer = styled.footer`
  ${tw`text-center text-grey absolute p-6 pb-1 font-sans text-md lg:text-lg`};
  bottom:0;
  width:100%;
`
const FooterWrapp = styled.div`
  position:${props=> props.position};
  width:100%;
  bottom:0;
`
const WaveWrapper = styled.div`
  ${tw`absolute w-full`};
  transform: matrix(1, 0, 0, -1, 0, 0);
  bottom:0;
`

const InnerWave = styled.div`
  ${tw`relative h-full`};
  svg {
    width: 100%;
    height: 40vh;
  }
  path {
    ${waveAnimation('20s')};
  }
`
const FooterTextWrap = styled.p`
  ${tw`my-0`};
`

const FooterWrapper = ({position='absolute'}) => {
    return (
        <FooterWrapp position={position}>
        <WaveWrapper>
        <InnerWave>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 338.05" preserveAspectRatio="none">
            <path fill="#000000d6">
              <animate
                attributeName="d"
                values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                repeatCount="indefinite"
                dur="30s"
              />
            </path>
          </svg>
        </InnerWave>
      </WaveWrapper>
        <Footer>
            <FooterTextWrap>&copy; 2022 all rights reserved.
            Made by  <a href="https://github.com/DasithKuruppu">Dasith Kuruppu</a>. &nbsp; </FooterTextWrap>
            <Link to='/privacy'>Our privacy policy </Link> 
        </Footer>
      </FooterWrapp>
 )
}
export default FooterWrapper;